<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card
					.clearfix.d-flex.j-between(slot="header")
						h2.no-line-height.text-left.w-100 Group {{type}}
						.extra
					el-row.text-left
						el-col
							p You must choose a subscription expiration date. Note, it will override the expiration date that was previously set. By default, the expiration time is in a week.
					el-form(ref="form" :model="form" :rules="rules" layout="vertical")
						el-row(:gutter="24")
							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item(label="Name" has-feedback="" ref="itemName" prop="name")
									el-input(v-model="form.name")

							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item( label="Subscription Until")
									el-date-picker.w-100(:picker-options="pickerOptions" placeholder="Subscription Deadline" v-model="form.expiration_date" format="yyyy/MM/dd" value-format="yyyy-MM-dd")
							el-col(:sm="6" :lg="4" :xl="3")
								el-form-item(label="Override Expiration" v-if="type==='Edit'")
									el-radio-group.w-100.text-left(v-model="form.override_expiration")
										el-radio(:label="true") On
										el-radio(:label="false") Off
							el-col(:span="24")
								el-form-item(label="Leads - Available / Target list")
									br
									el-transfer.text-left.lead-box(filterable="" :data="options.lead" v-model="form.users" :titles="['Available','Target']")
					el-divider
					el-row(:gutter="12")
						el-col(:span="24")
							el-button(type="primary" size="midi" @click="submit") Save
							el-button(type="danger" size="midi" @click="$router.push('/group/list')") Cancel

</template>

<style lang="scss">
	.lead-box {
		.el-transfer-panel {
			width: 300px;
		}
	}
</style>

<script>
import {CreateEntity, GetEntity, UpdateEntity} from "@/api/group";
import {GetOptions} from "@/api/option";
import {validateAlphaNumeric, validateEntityName} from "@/utils/validate";
import moment from 'moment';

const $form = {
	name: '',
	users: [],
	expiration_date: moment().add(7, 'days').format('YYYY-MM-DD'),
	override_expiration: false
}

export default {
	async beforeRouteEnter(to, from, next) {
		console.log(to.path === '/group/create')
		if(to.path !== '/group/create' && to.path !== '/group/edit/' + to.params.id) return next('/group/list');
		if(to.path === '/group/create') {
			try {
				const options = await GetOptions({filters:'lead'});
				console.log(options.lead.map(e => Object.assign({}, { key: e.v, label: e.t })))
				next(vm => {
					vm.form = {...$form};

					vm.type = 'Create';
					vm.options = [];
					vm.options.lead = options.lead.map(e => Object.assign({}, { key: e.v, label: e.t + ', ' + (e.cn || e.fn) }))
				})

			} catch(e) {
				console.error(e);
				return next('/group/list');
			}
		} else if(to.path === '/group/edit/' + to.params.id) {
			try {
				let entity = await GetEntity(to.params.id);
				const options = await GetOptions({filters:'lead'})
				next(vm => {

					vm.type = 'Edit';
					vm.options = [];
					vm.form = {...$form};
					vm.form.name = entity.name;

					vm.options.lead = options.lead.map(e => Object.assign({}, { key: e.v, label: e.t + ', ' + (e.cn || e.fn) }))
					vm.form.users = entity.leads.map(e => e.guid)
				})
			} catch(e) {
				console.error(e);
				return next('/group/list');
			}
		}
	},
	data() {
		return {
			type: '',
			form: {...$form},
			filterMethod(query, item) {
				return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
			},
			rules: {
				name: { required: true, validator: validateEntityName, trigger: 'blur' },
			},
			options: {
				user: []
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now();
				}
			}
		}
	},
	methods: {
		submit() {
			this.$refs.form.validate(async valid => {
				if(!valid) return this.$message.error('Validation failed');
				let loader = this.$loading.show();
				if (this.type === 'Create') {
					CreateEntity(this.form)
							.then(res => {
								loader.hide();
								this.$notify.success({title: 'API Response', message: 'Group successfully created'});
								this.$router.push('/group/list');
							})
							.catch(err => {
								console.error(err);
								loader.hide();
							});
				} else if (this.type === 'Edit') {
					UpdateEntity(this.$route.params.id, this.form)
							.then(res => {
								loader.hide();
								this.$notify.success({title: 'API Response', message: 'Group successfully updated'});
								this.$router.push('/group/list');
							})
							.catch(err => {
								loader.hide();
								console.error(err);
							});
				}
			});
		}
	}
}
</script>
